import React from 'react';
import { renderToString } from 'react-dom/server';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import { IconProps } from 'components/ui/Icon';

import { SectionTitle } from 'helpers/definitions';

import * as Styled from './styles';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Button from '../ui/Button';
import useForm, { FormProvider } from '../../helpers/useForm';
import getSchema, { ContactSchema } from './contactSchema';
import { useAppDispatch } from '../../infrastructure/store';
import { NotificationVariant, setNotification } from '../../infrastructure/notifications';
import { TextField } from '../TextField/TextField';
import HorizontalTitleSeparator from '../HorizontalTitleSeparator/HorizontalTitleSeparator';

interface Contact {
  node: {
    id: string;
    frontmatter: {
      title: string;
      content: string;
      icon: IconProps;
    };
  };
}

const getBody = (values: ContactSchema) => (
  <html>
    <body>
      {Object.keys(values).map(key => (
        <p key={key}>
          <strong>{key}:</strong> {values[key as keyof typeof values]}
        </p>
      ))}
    </body>
  </html>
);

const ContactInfo: React.FC = () => {
  const { t } = useI18next();
  const dispatch = useAppDispatch();

  const addNotification = (type: NotificationVariant, title: string) => {
    dispatch(
      setNotification({
        type,
        title,
      })
    );
  };

  const contactForm = useForm<ContactSchema>({
    validationSchema: getSchema({
      nameRequired: t('nameRequired'),
      emailRequired: t('emailRequired'),
      emailFormat: t('emailFormat'),
      phoneRequired: t('phoneRequired'),
      phoneFormat: t('phoneFormat'),
      cityRequired: t('cityRequired'),
      describeRequired: t('describeRequired'),
    }),
    initialValues: {
      name: '',
      email: '',
      phone: '',
      city: '',
      describeYourIssue: '',
    },
    enableReinitialize: true,
    onSubmit: (values: ContactSchema) => {
      // @ts-ignore
      Email.send({
        Host: 'smtp.gmail.com',
        Username: 'kingawebsitemail@gmail.com',
        Password: 'ferhemelleny1',
        To: 'herceg.legal@gmail.com',
        From: 'kingawebsitemail@gmail.com',
        Subject: 'herceg kinga website message',
        Body: renderToString(getBody(values)),
      }).then((message: any) => {
        if (message === 'OK') {
          contactForm.resetForm();
          addNotification('success', t('messageSentSuccessfully'));
        }
      });
    },
  });

  const { markdownRemark, allMarkdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "contact section" } }) {
        frontmatter {
          title
          subtitle
        }
      }
      allMarkdownRemark(filter: { frontmatter: { category: { eq: "contact" } } }) {
        edges {
          node {
            id
            frontmatter {
              title
              icon
              content
            }
          }
        }
      }
    }
  `);

  const sectionTitle: SectionTitle = markdownRemark.frontmatter;
  const contacts: Contact[] = allMarkdownRemark.edges;

  return (
    <Container section>
      <HorizontalTitleSeparator title={t('contactPage')} additionalClass="mt-16 mb-24" />
      <div className="grid grid-cols-2 w-full items-start justify-center gap-20">
        <div
          className="col-span-2 lg:col-span-1 flex flex-col justify-start items-start mx-auto"
          style={{ maxWidth: '500px' }}
        >
          <div className="">
            <b>{t('step')} 1:</b> {t('step1')}
          </div>
          <div className="mt-10">
            <b>{t('step')} 2:</b> {t('step2')}
          </div>
          <div className="mt-10">
            <b>{t('step')} 3:</b> {t('step3')}
          </div>
          <div className="mt-10">
            <b>{t('step')} 4:</b> {t('step4')}
          </div>
          <div className="mt-10 mb-16">
            <b>{t('step')} 5:</b> {t('step5')}
          </div>

          <TitleSection title="&nbsp;" center subtitle={t('contactForm')} />
          <FormProvider form={contactForm}>
            <Styled.Form>
              <TextField size="m" label={t('name')} type="text" name="name" autoComplete="off" />
              <TextField size="m" label={t('email')} type="text" name="email" autoComplete="off" />
              <TextField size="m" label={t('telephone')} type="tel" name="phone" autoComplete="off" />
              <TextField size="m" label={t('city')} type="text" name="city" autoComplete="off" />
              <TextField
                multiline
                placeholder={t('describeYourIssuePlaceholder')}
                size="m"
                label={t('describeYourIssue')}
                type="text"
                name="describeYourIssue"
                autoComplete="off"
              />
              <Button
                type="submit"
                primary
                block
                style={{
                  maxWidth: '200px',
                  marginBottom: '4px',
                  border: '2px solid #e45a11',
                  color: '#e45a11',
                  background: 'transparent',
                  fontWeight: 'bold',
                }}
                disabled={!contactForm.isValid}
              >
                {t('send')}
              </Button>
              <b className="mt-4">{t('answerIn')}</b>
            </Styled.Form>
          </FormProvider>
          {/*<TitleSection title="&nbsp;" center subtitle={t('contactTitle')} />*/}
          {/*<div className="flex">*/}
          {/*  {contacts.map(item => {*/}
          {/*    const {*/}
          {/*      id,*/}
          {/*      frontmatter: { title, icon, content },*/}
          {/*    } = item.node;*/}

          {/*    return (*/}
          {/*      <Styled.ContactInfoItem key={id}>*/}
          {/*        <InfoBlock icon={icon} title={title} content={content} center />*/}
          {/*      </Styled.ContactInfoItem>*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</div>*/}
        </div>
        <div className="flex flex-col items-center justify-start col-span-2 lg:col-span-1">
          <TitleSection small subtitle={`${t('address')}: Str. Rovine Nr. 2`} center />
        {/*  <iframe*/}
        {/*    width="100%"*/}
        {/*    height="auto"*/}
        {/*    style={{ border: 0, width: '100%', height: 'auto', minHeight: '500px' }}*/}
        {/*    loading="lazy"*/}
        {/*    allowFullScreen*/}
        {/*    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAr9UYyeoLQLheHZH_gTyyacTZW2RRMEDg*/}
        {/*&q=46.781919,23.608562&zoom=18"*/}
        {/*    className="col-span-2 lg:col-span-1"*/}
        {/*  />*/}
          <div className="col-span-2 lg:col-span-1" style={{ width: '100%', height: 'auto', border: 0, minHeight: '500px' }}>
            <gmp-map center="46.78190612792969,23.608612060546875" zoom="15" map-id="cabinet-map-id" style={{ width: '100%', height: '500px', border: 0, minHeight: '500px' }}>
              <gmp-advanced-marker position="46.78190612792969,23.608612060546875" title={t('lawOfficeHerceg')}></gmp-advanced-marker>
            </gmp-map>
          </div>
          {/* src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAr9UYyeoLQLheHZH_gTyyacTZW2RRMEDg */}
          {/*&q=Rovine+3+-1+Cluj-Napoca,Romania&zoom=15"*/}
          <TitleSection title="&nbsp;" center subtitle={t('program')} />
          <div className="mx-auto mb-10 grid grid-cols-3 items-center justify-center gap-10">
            <div className="flex flex-col items-center col-span-2 justify-center">
              <div>
                {t('monday')},&nbsp;{t('wednesday')},&nbsp;{t('friday')}
              </div>
              <div>
                {t('tuesday')},&nbsp;{t('thursday')}
              </div>
              <div>
                {t('saturday')},&nbsp;{t('sunday')}
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <div>&nbsp;09:00&nbsp;-&nbsp;17:00</div>
              <div>&nbsp;12:00&nbsp;-&nbsp;20:00</div>
              <div>&nbsp;{t('closed')}</div>
            </div>
            <div className="flex text-center items-center justify-center col-span-3 mb-16">{t('exceptions')}</div>
            <div className="flex flex-col items-center mx-auto w-full col-span-3">
              <div className="flex text-left items-start justify-start col-span-3" style={{ color: '#312e91', fontWeight: 'bold' }}>{t('programContact')}</div>
              <div className="flex text-left items-start justify-start col-span-3">{t('tel')}&nbsp;0746 038 556</div>
              <div className="flex text-left items-start justify-start col-span-3">
                <a target="_blank" href="https://calendly.com/avocat-szilagyi-kinga">
                  {t('booking')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ContactInfo;
