import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// import Img, { FluidObject } from 'gatsby-image';
import LogoImage from '../../../assets/images/svg/logo.svg';
import * as styles from './Logo.module.scss';

import * as Styled from './styles';

// import { ImageSharpFluid } from 'helpers/definitions';

const Logo: React.FC = () => {
  const { site, placeholderImage } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      placeholderImage: file(relativePath: { eq: "profile.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const logoTitle: string = site.siteMetadata.title;

  return (

    <Styled.Logo to="/">
      {/* @ts-ignore */}
      <LogoImage alt="Herceg Kinga Lawyer" title="Herceg Kinga Lawyer" className={styles.logo} />
       {/*<Styled.Image>*/}
       {/*  <Img fluid={logoImage} alt="Logo" />*/}
       {/*</Styled.Image>*/}
       <Styled.Text className="md:-ml-16">{logoTitle}</Styled.Text>
     </Styled.Logo>
  );
};

export default Logo;
